.assurance-sub-service-list {
  list-style: none; /* Remove default list styling */
  padding: 0;
}

.assurance-sub-service-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
}

.assurance-circle {
  width: 10px; /* Circle diameter */
  height: 10px; /* Circle diameter */
  border-radius: 50%; /* Make it a circle */
  background-color: black; /* Circle color */
  margin-right: 10px; /* Space between circle and text */
}

.assurance-sub-service-text {
  flex-grow: 1; /* Allows text to take remaining space */
  margin-right: 5px; /* Reduced space between text and arrow */
}

.assurance-arrow-icon {
  width: 20px; /* Adjust arrow icon size if needed */
  height: 20px; /* Adjust arrow icon size if needed */
  cursor: pointer; /* Pointer cursor on hover */
}
