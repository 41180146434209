/* TeamNewPage.css */

.team-new-page-advisor-card {
  display: flex;
  flex-direction: column; /* Stack cards vertically on small screens */
  align-items: flex-start; /* Align the cards to the left */
  padding: 20px;
}

/* Card container styles */
.team-new-page-card-container {
  display: flex;
  flex-direction: column; /* Stack image and details vertically */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 20px; /* Rounded corners */
  margin: 10px;
  padding: 15px;
  max-width: 1000px; /* Increase max width for cards */
  width: 100%; /* Ensure cards take full width on smaller screens */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Box shadow */
  transition: transform 0.2s; /* Animation on hover */
}

.team-new-page-card-container:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
}

.team-new-page-image-card {
  display: flex;
  justify-content: center; /* Center image */
  margin-bottom: 10px; /* Space between image and details */
}

.team-new-page-profile-img {
  border-radius: 50%; /* Circular profile images */
  width: 200px; /* Fixed width for images */
  height: 200px; /* Fixed height for images */
  object-fit: cover; /* Ensure images cover the area */
  border: 5px solid #34a853;
}

.team-new-page-profile-details {
  text-align: left; /* Align text to the left */
}

.team-new-page-name-designation-container {
  margin-bottom: 10px; /* Space between name and title */
}

.team-new-page-profile-name {
  font-size: 1.5em; /* Larger font size for names */
  margin: 0; /* Remove margin */
}

.team-new-page-profile-title {
  font-size: 1.2em; /* Slightly smaller font size for titles */
  color: #555; /* Dim color for titles */
  text-align: left; /* Align title to the left */
}

.team-new-page-profile-description {
  margin: 10px 0; /* Space around description */
  font-size: 1em; /* Default font size for description */
  text-align: left; /* Align description to the left */
}

.team-new-page-linkedin-container {
  margin-top: 10px; /* Space above LinkedIn logo */
}

.team-new-page-linkedin-logo {
  width: 24px; /* Fixed width for LinkedIn logo */
  height: 24px; /* Fixed height for LinkedIn logo */
}
.back-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #34a853;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  margin: 20px 0;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #2b7a3e;
}


/* Responsive styles */
@media (max-width: 767px) {
  .team-new-page-advisor-card {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .team-new-page-card-container {
    margin: 10px; /* Adjust margin for smaller screens */
    padding: 10px; /* Reduced padding for card */
    flex-direction: column; /* Ensure cards stack vertically */
  }

  .team-new-page-profile-img {
    width: 150px; /* Smaller image size on small screens */
    height: 150px; /* Smaller image size on small screens */
  }

  .team-new-page-profile-name {
    font-size: 1.2em; /* Smaller font size for names on small screens */
  }

  .team-new-page-profile-title {
    font-size: 1em; /* Smaller font size for titles on small screens */
  }

  .team-new-page-profile-description {
    font-size: 0.9em; /* Smaller font size for description */
  }
}

@media (min-width: 768px) {
  .team-new-page-advisor-card {
    flex-direction: row; /* Align cards in a row on larger screens */
    flex-wrap: wrap; /* Allow wrapping to new lines */
    justify-content: center; /* Center cards */
  }

  .team-new-page-card-container {
    margin: 15px; /* Space between cards */
  }
}
