/* Container for the content */
.content {
  z-index: 1;
  /* margin-top: -2%;  */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Button container to ensure buttons are in a row */
.button-container {
  margin-top: 10px; /* Move buttons up */
  display: flex;
  justify-content: center;
  gap: 10px; /* Add space between buttons */
  flex-wrap: wrap; /* Ensure buttons wrap in smaller screens */
}

/* Button styles */
.btnbtn {
  width: 120px;
  height: 50px;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: all 0.3s ease; /* Add smooth hover effect */
}

.btnbtn img {
  width: 20px;
  margin-right: 8px;
}

@media (max-width>=1400px){
  .content{
    margin-top:-45%;
  }
}
@media (max-width:1200px){
  .content{
    margin-top: -2%;
  }
}
/* Media query for responsiveness */
@media (max-width: 768px) {
  /* Adjust content position for smaller screens */
  .content {
    margin-top: 25%;
  }

  /* Adjust button size and alignment on smaller screens */
  .btnbtn {
    width: 100px;
    height: 40px;
  }

  .button-container {
    gap: 5px; /* Reduce gap between buttons */
    flex-direction: row; /* Ensure buttons stay in a row */
  }

  .mobileview h1 b {
    font-size: 40px; /* Make heading smaller for mobile */
  }

  .herotxt {
    font-size: 14px; /* Smaller text on mobile */
  }
}

@media (max-width: 480px) {
  /* Further adjustments for very small screens */
  .content{
    margin-top: 50%;
  }
  .btnbtn {
    width: 90px;
    height: 35px;
  }

  .mobileview h1 b {
    font-size: 30px; /* Adjust heading font size */
  }

  .herotxt {
    font-size: 12px; /* Further reduction of text size */
  }
}




.bgwrapper{
  position: relative;
  height: 100vh;
  z-index: 20;
  /* width: 100vw; */
  min-height: 700px;
  overflow: hidden;
  background-color: #ebeae8;
}



.wrapperstyle{
  z-index: 0;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.wrapperbg{
  /* width: fit-content;
  min-width: 100%;
  overflow: hidden;
  aspect-ratio: 16/9!important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 50%; */
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: -webkit-fit-content;
  width: fit-content;
  min-width: 100%;
  overflow: hidden;
  aspect-ratio: 16/9!important;
}

.bgwrapperspan{
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}

.bgwrapperspan{
  box-sizing: border-box;
  display: block;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  max-width: 100%;
}

.wrapperimg{
  display: block;
  max-width: 100%;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.wrapperimg1{
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}
