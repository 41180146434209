.team-section {
  text-align: center;
  padding: 0 15%; /* Padding around the entire section */
 
  font-family: "Merriweather sans", sans-serif;
}

/* Consistent padding and spacing between section elements */


.section-title {
  font-size: 2em;
  color: #34a853;
  margin-bottom: 20px;
}

.introduction {
  font-size: 1.1em;
}

.impact-section {
  text-align: center;
  padding: 20px;
}

.impact-list {
  list-style-type: none;
  padding-left: 0;
}

.impact-item {
  font-size: 1em;
  margin: 10px 0;
}

.vision-mission-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
}

.vision-section,
.mission-section {
  width: 45%;
  padding: 20px 20px;
  
}

.values-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.value-card {
  background-color: #1a1a1a;
  border-radius: 10px;
  color: white;
  padding: 20px;
  width: 250px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.value-card:hover {
  transform: scale(1.05);
}

.value-title {
  font-weight: bold;
  color: #34a853;
  margin-bottom: 10px;
  font-size: 1.1em;
}

.staff-section {
  margin-top: 40px; /* Consistent spacing */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .section-title {
    font-size: 1.5em;
  }

  .introduction,
  .impact-item,
  .value-title {
    font-size: 0.9em;
  }

  .vision-mission-container {
    flex-direction: column;
    width: 100%;
  }

  .vision-section,
  .mission-section {
    width: 90%;
    padding: 0;
  }

  .values-cards {
    flex-direction: column;
    align-items: center;
  }

  .value-card {
    width: 80%;
    max-width: 300px;
  }

  .impact-list {
    padding-left: 0;
    text-align: center;
  }

  .impact-item {
    font-size: 0.9em;
  }

  .team-section {
    padding: 0 10px; /* Reduced padding for smaller screens */
  }

  .team-section > * {
    padding: 0 10px;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.2em;
  }

  .introduction,
  .impact-item,
  .value-title {
    font-size: 0.8em;
  }

  .vision-section,
  .mission-section {
    min-height: auto;
  }

  .staff-section {
    margin-top: 10px;
  }

  .team-section {
    padding: 0 5px; /* Minimal padding on smaller screens */
  }

  .team-section > * {
    padding: 0 5px;
    margin-bottom: 20px; /* Reduced spacing */
  }
}
