

@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;1,300&display=swap');


body {
  font-family: "Merriweather sans" , sans-serif;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  scroll-behavior:smooth;
  overflow: auto;
}


code {
  font-family: "Merriweather sans" , sans-serif,
    monospace;
}

.zoom1{
  transition-timing-function: ease-in-out;
  transition-delay: 3s;
  animation: transitionIn 6s;
}

@keyframes transitionIn{
  from{
    opacity: 1;
    zoom: 0%;
  }
  to{
    opacity: 1;
    zoom: 30%;
  }
}

.image_remove{
  position: relative;
  height: 100vh;
  /* width: 100px; */
  max-height: fit-content;
  /* background-color: red; */
  position: relative;
  animation-name: example;
  animation-duration: 3s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

@keyframes example {
  /* 0% {left:0px; top:-600px;}
  100%  {left:0px; top: 0px;} */

  from{
    transform: translateY(-100%) translateZ(0px);
  }
  to{
    transform: translateY(0%) translateZ(0px);
  }
}

  /* border-bottom: 10vw solid transparent; border-bottom-left-radius: 10%; */
  
  
.about-us-conatiner{
     font-family: "Merriweather sans" , sans-serif,
}




.image2{
  position: relative;
  height: 100vh;
}

.shape1{
  z-index: 1;
  width: 0;
  height: 0;
  border-bottom: 20vw solid #082f48;
  border-right: 98vw solid transparent;
  opacity: .7;
  /* margin-top: -308px; */

}

.shape2{
  z-index: 1;
  width: 0;
  height: 0;
  border-top: 20vw solid #082f48;
  border-left: 98vw solid transparent;
  opacity: .7;
  width: 100%;
}

.shape3{
  z-index: 1;
  width: 0;
  height: 0;
  border-top: 20vw solid #333;
  border-left: 98vw solid transparent;
  /* opacity: .7; */
  width: 100%;
}

.triangleLeft {
	/* width: 0;
	height: 0; */
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	border-bottom: 200px solid #edf4ed;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.triangleRight{
  border-right: 0px solid transparent;
	border-left: 0px solid transparent;
	border-bottom: 200px solid #edf4ed;
  position: absolute;
  bottom: 0px;
  right: 0px;
  
}

.section{
  position: relative;
  z-index: 1;
  width: 100%;
}

.justdiv{
  position: relative;
}

/* .Polygon-3 {
  width: 717.7px;
  height: 363.3px;
  margin: 0 1.3px 0 0;
  background-color: #613b3b;
}

.right_bottom{
  position: absolute;
  right: 0;
  bottom: -0.5px;
  z-index: 1;
  width: 0;
  height: 0;
  /* border-bottom: 20vw solid #353535;
  border-left: 50vw solid transparent;
}

.left_bottom{
  position: absolute;
  left: 0;
  bottom: -0.5px;
  z-index: 1;
  width: 0;
  height: 0;
   border-bottom: 20vw solid #ebeae8; 
  border-right: 50vw solid transparent;
} */

.content{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 112vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.btnbtn{
  font-family: "Merriweather sans" , sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 8px;
  /* transition: 0.5s; */
  line-height: 1;
  margin: 10px;
  color: #34a853;
  /* animation-delay: 0.8s; */
  border: 2px solid #fff;
  text-align: center;
}

.btnbtn1{
  font-family: "Merriweather sans" , sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 8px;
  line-height: 1;
  margin: 2px;
  color: #fff;
  border: 2px solid #34a853;
  text-align: center;
}

 /* button{
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  color: #555555;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-decoration: none;
  font-size: 24px;
  overflow: hidden;
  transition: 0.2s;
}

button:hover{
  color: #000000;
  background: #ffffff;
  box-shadow: 0 0 10px #ffffff, 0 0 40px #ffffff, 0 0 80px #ffffff;
  transition-delay: 1s; 
} */

.content span.img-txt{
  background-color: #333;
  /* text-transform: uppercase; */
  color: #fff;
  padding: 1rem;
  font-size: 1.5rem;
  /* letter-spacing: 1px; */
}

.overlay{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0.75);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

/* h3 {
  letter-spacing: 3.6px;
  text-transform: uppercase;
  font: 1.3rem;
  text-align: center;
} */

h3{
  letter-spacing: 3.6px;
  color: #34a853;
  text-align: center;
  font-size: 1.7em;
  text-transform: uppercase;
  font-family: "Merriweather sans" , sans-serif;
  /* font-family: RT Rondelle Medium; */
}

h4 {
  font-size: 1.4em;
  letter-spacing: 1.16px;
  font-family: "Merriweather sans" , sans-serif;
  font-weight: 400;
}


.text-box{
  height: 600px;
  text-align: center;
  padding: 3rem 1rem;
  text-align: justify;
  color: #fff;
  background-color: #082f48;
  opacity: .7;
}

/* .text-box-2{
  text-align: center;
  background-image: url("https://i.natgeofe.com/k/830b5d15-92db-429f-a80a-cc89b5700af5/mt-everest.jpg?w=636&h=437");
  background-size: cover;
  opacity: 0.5;
  padding: 3rem 1rem;
  text-align: justify;
  color: rgb(0, 0, 0);
  background-color: #333;
} */

.LastBox{
  text-align: center;
  background-image: url("https://wallpaperaccess.com/full/1457254.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 98.9vw;
  height: 100vh;
  opacity: 0.5;
  padding: 3rem 1rem;
  text-align: center;
  color: rgb(0, 0, 0);
  background-color: #333;
}

.section1{
  position: relative;
  z-index: 1;
  width: 100%;
}

.footer{
  text-align: center;
}


ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-family: "Merriweather sans" , sans-serif;
}

.anima{
  position: absolute;
  height: calc(100% - 7em);
  width: 100%;
  top: 0;
  left: 0;
  border-right: 50vw solid rgba(8,24,34,.5);
  border-bottom: 20vw solid transparent;
}


.leftSide{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #081822;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.cornerStyle{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 20vw solid #fff;
  border-left: 50vw solid transparent;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.lineAnima{
  width: 5px;
  height: 100px;
  background: rgb(255, 255, 255);
  transition-property: height;
  transition-duration: 5s;
  transition-delay: 0s;
}

.lineAnima:hover {
  height: 300px;
}

/* navbar */
.txt:hover {
  text-decoration: underline;
}

.trans{
  transition-timing-function: ease-in-out;
  animation: transitionIn 2s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
}

@keyframes transitionIn{
  from{
    opacity: 1;
    border-left: 0px solid transparent;
	  border-right: 50vw solid transparent;
	  border-bottom: 0px solid rgb(52, 168, 83, 0.6);
    bottom: 0px;
    left: 0px;
  }
  to{
    opacity: 1;
    border-left: 0px solid transparent;
	  border-right: 50vw solid transparent;
	  border-bottom: 200px solid rgb(52, 168, 83, 0.6);
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
}

.trans2{
  transition-timing-function: ease-in-out;
  animation: transitionIn2 2s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
}

@keyframes transitionIn2{
  from{
    opacity: 1;
    border-right: 0px solid transparent;
	  border-left: 50vw solid transparent;
	  border-bottom: 0px solid rgb(52, 168, 83, 0.6);
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  to{
    opacity: 1;
    border-right: 0px solid transparent;
	  border-left: 50vw solid transparent;
	  border-bottom: 200px solid rgb(52, 168, 83, 0.6);
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}

.FootEr{
  background-color: #000000;
  position: relative;
  color: #fff;
}

.FooterBox{
  position: relative;
  padding-top: 6em;
}

.FooterContent{
  position: relative;
  padding: 5em 0;
  margin: 3em 0;
  padding-bottom: 4em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12em;
  gap: 12em;
}

@media screen and (max-width: 940px) {
  .FooterContent{
    display: block;
  }
}


.Divider{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  width: 1px;
  /* height: 100%; */
  background-color: #fff;
}

.LeftContent{
  text-align: right;
}

.RightContent{
  position: relative;
  z-index: 4;
  top: 17px;
}

.FootTxt{
  margin-top: 1.4em;
  font-family: "Merriweather sans" , sans-serif;
}

.TxtPara{
  color: #fff;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  font-size: 1.2em;
}

@media screen and (max-width: 940px) {
  .TxtPara{
    text-align: center;
    justify-content: center;
  }
  .Divider{
    display: none;
  }
  .FooterContent{
    padding: 3em;
  }
  .UnderLine{
    margin-inline: auto;
  }
}

.UnderLine{
  max-width: 100px;
  height: 1px;
  background-color: #34a853;
  margin-left: auto;
}

.Form1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  gap: 1em;
}

@media screen and (max-width: 940px){
  .Form1{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0em;
    gap: 0em;
  }
}



.Form12 {
  padding: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #fff;
}

.InputBox{
  background-color: transparent;
    border: 1px solid #fff;
    padding: 0.9em 1em;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    color: #fff;
    font-size: 1em;
    outline: none;
    text-decoration-color: #fff;
    user-select: none;
    background-image: none!important;
}

.FormEmail{
  padding: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #fff;
}

.FormButton{
  position: relative;
  border: 0;
  background-color: transparent;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0.6em;
  margin: 4px 2px;
  cursor: pointer;
  transition: gap .3s ease-in-out;
  color: #ed462f !important;
  padding-top: 1em;
}

.Train{
  opacity: 1;
  transform: translateY(0%) translateZ(0px);
  position: relative;
  z-index: 1;
  width: 100%;
  margin-bottom: 10%;
}

.CornerLeft{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 0;
  height: 0;
  border-top: 20vw solid #fff;
  border-right: 50vw solid transparent;
}

.CornerRight{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 0;
  height: 0;
  border-top: 20vw solid #fff;
  border-left: 50vw solid transparent;
}

.maincontent{
  min-height: 700px;
  padding-top: 3em;
  display: flex;
  align-items: center;
}

.bgImage{
  object-position: 50% 35%;
  z-index: 0;
  overflow: hidden;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.styleimage{
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  max-width: 100%;
}

.spanstyle1{
  box-sizing: border-box;
  display: block;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.trainimg{
  display: block;
  max-width: 100%;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bgimagefull{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.ContStyle{
  z-index: 1;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 2em 0;
  box-sizing: border-box;
}

.line2{
  width: 1px;
  max-height: calc(25% - 3em);
  background-color: #34a853;
  position: absolute;
  top: 3em;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
  height: 100%;
}

.traincontent{
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* font-size: small; */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
@media screen and (max-width: 940px) {
  .traincontent{
    font-size: 0.7em;
    top: -50px;
    position: relative;
  }
  .maincontent{
    min-height: 250px;
  }
}


*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.mountbg{
  padding: 0;
  /* margin-top: -1em; */
  position: relative;
  width: 100%;
  background-color: #ebeae8;
  overflow: hidden;
}

.sec1{
  position: relative;
  padding-top: 3em;
  z-index: 2;
  width: 100%;
}

.sec1cont{
  z-index: 1;
  max-width: 1200px;
  width: 90%;
  width: 90%;
  margin: 0 auto;
  /* padding: 2em 0; */
  padding-top: 2em;
  padding-bottom: 4em;
}

.line3{
  width: 1px;
  max-height: calc(100% - 7.4em + 24vw - 3em);
  background-color: #34a853;
  position: absolute;
  top: 7.4em;
  left: 50%;
  transform: translate(-50%);
  z-index: 3;
}

.mountbgtxt{
  display: grid;
  
  grid-template-columns: 1fr 1fr;
  grid-gap: 11em;
  gap: 11em;
  padding-top: 3em;
}

@media screen and (max-width: 940px) {
  .mountbgtxt{
    font-size: 0.7em;
    text-align: center;
    display: inherit;
  }
  h4{
    text-align: center;
    padding-bottom: 1em;
  }
  .line3{
    max-height: calc(0% - 7.4em + -68vw - 6.2em);
    top: 14em;
  }
  .itembox{
    font-size: 0.7em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4em;
    text-align: center;
  }
  .itembox1{
    display:  -webkit-inline-box;
  }
  
}



.parah{
  /* padding-bottom: 1em; */
  font-size: 1.2em;
  letter-spacing: .45px;
  color: #01232c;
  line-height: 1.6em;
  font-family: "Merriweather sans" , sans-serif;
  padding-right: 2em;
}

@media screen and (max-width: 940px) {
  .parah{
    /* padding-bottom: 1em; */
    padding-right: 0em;
  }
}

.sec2{
  position: relative;
  z-index: 1;
  width: 100%;
}

.linesmall{
  width: 1px;
  height: 20vw;
  position: absolute;
  top: 0;
  left: 50%;
  background-color: #cd8771;
  display: none;
}

.shapecurve{
  z-index: 1;
  width: 0;
  height: 0;
  border-bottom: 20vw solid #000000;
  border-left: 100vw solid transparent;
  opacity: .7;
}

.mtbody{
  position: relative;
  background-color: rgba(0, 0, 0, 0.7);
  /* opacity: 0.5; */
  padding-bottom: 6em;
}

.slogan{
  color: #fff;
  font-size: 2.4em;
  padding-bottom: 1em;
  font-family: "Merriweather sans" , sans-serif;
  opacity: .64;
  line-height: 1.6em;
  letter-spacing: .45px;
}

.itembox{
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4em;
  gap: 3em;
  row-gap: 6em;
  font-family: "Merriweather sans" , sans-serif;
}

.newgrid{
  position: relative;
  display: grid;
  align-items:center;
  text-align: center;
  grid-template-columns: 1fr 1fr ;
  
  
  row-gap: 4em;
  font-family: "Merriweather sans" , sans-serif;
}

.newgrid_ipad{
  position: relative;
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2em;
  gap: 3em;
  row-gap: 4em;
  font-family: "Merriweather sans" , sans-serif;
  display: none;
}

.imgcenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgwidth{
  width: 400px;
}

@media screen and (max-width: 1400px) {
  .imgwidth{
    width: 341px;
  }
}

@media screen and (max-width: 1289px) {
  .newgrid{
    /* grid-template-columns: 1fr 1fr; */
    display: none;
  }
  .newgrid_ipad{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .imgwidth_ipad{
    width: 246px;
  }
}

@media screen and (max-width: 940px) {
  .newgrid{
    grid-template-columns: 1fr;
  }
  .newgrid_ipad{
    display: grid;
    grid-template-columns: 1fr;
  }
  .imgwidth{
    width: 300px;
  }
}

@media screen and (max-width: 940px) {
  .itembox{
    grid-template-columns: 1fr 1fr;
    gap: 4em;
    text-align: center;
  }

}

/* .mobileview{
  bottom: 199px;
  position: absolute;
}

@media screen and (max-width: 940px) {
  .mobileview{
    bottom: 333px;
  }
} */

.itemboxnew{
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4em;
  gap: 4em;
  padding-top: 48px;
}

.itembox1{
  width: 52px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (max-width: 940px) {
  .itembox1{
    display: -webkit-inline-box;
  }
}

.itemicon{
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}

.itemicon1{
  box-sizing: border-box;
  display: block;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  max-width: 100%;
}

.icon1{
  display: block;
  max-width: 100%;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.imgsrcset{
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.itemboxhead{
  padding-top: 0.6em;
  font-size: 1.2em;
  font-family: "Merriweather sans" , sans-serif;
  line-height: 1.6em;
  color: rgb(255, 255, 255);
  letter-spacing: .45px;
}

.itemboxhead2{
  /* padding-top: 0.6em; */
  margin-bottom: 0px;
  font-size: 1.6em;
  /* font-family: Made Mirage Medium; */
  font-family: "Merriweather sans" , sans-serif;
  line-height: 1.6em;
  /* color: rgb(0, 0, 0); */
  color: #34a853;
  letter-spacing: .45px;
}

.line4{
  width: 1px;
  max-height: 20vw;
  background-color: #34a853;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
  height: 100%;
}

.traiangleMid{
  z-index: 1;
  /* width: 0; */
  height: 0;
  border-top: 20vw solid #000000;
  border-right: 100vw solid transparent;
  opacity: .7;
  width: 100%;
}

.cornerimg{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 49vw;
  height: 20vw;
  /* -o-object-fit: cover; */
  object-fit: cover;
}

.bottomtraingle{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10vw;
  border-bottom: 10vw solid #fff;
  border-right: 50vw solid transparent;
  border-left: 50vw solid transparent;
}

.mountbgimg{
  width: fit-content;
  min-width: 100%;
  position: absolute;
  top: 0;
  /* left: 50%; */
  /* transform: translate(-50%); */
  min-height: 100%;
  z-index: 0;
  /* -o-object-fit: cover; */
  object-fit: cover;
  aspect-ratio: 1/1;
}

.mountimage{
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  /* height: initial; */
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%!important;
  max-width: none!important;
  min-width: 100%;
}

.secondlasttxt{
  position: relative;
  padding-top: 3em;
  padding-bottom: 6em;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em 0;
}

.finalline{
  width: 1px;
  max-height: calc(66% - 11.5em);
  background-color: #34a853;
  position: absolute;
  top: calc(30% + 9em);
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
  height: 100%;
}

.finaltxtbox{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 11em;
  gap: 11em;
  row-gap: 0em;
  padding-top: 3em;
}

@media screen and (max-width: 940px) {
  .finaltxtbox{
    font-size: 0.7em;
    display: inherit;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
  }
  h4{
    display: block;
  }
  .finalline{
    max-height: calc(66% - 19em);
    top: calc(30% + 0em);
  }
}

.headerstyle{
  width: 98vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.nav1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  min-height: 80px;
  height: 20vh;
  width: 90%;
  margin: 0 auto;
  padding: 2em 0;
}

.logoimg{
  cursor: pointer;
  width: 220px;
  height: auto;
}

.logostyle{
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}

.navtabs{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tab1{
  width: 90%;
  max-width: calc(771px - 6em);
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.1em;
  margin-left: auto;
  text-transform: uppercase;
}

.bannerstyle{
  font-style:  "Merriweather sans" ;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  min-height: 700px;
  overflow: hidden;
  z-index: 1;
}

@media screen and (max-width: 940px) {
  .bannerstyle{
    font-size: 0.7em;
  }
}

.leftban{
  z-index: 2;
  position: absolute;
  top: calc(58% - 14em);
  left: 0;
  transform: translateY(-50%);
}

.leftban1{
  z-index: 2;
  position: absolute;
  top: calc(58% - 14em);
  /* left: 0; */
  right: 0;
  transform: translateY(-50%);
}
 
/* h1{
  position: relative;
  color: #fff;
  font-size: 4em;
  font-weight: 400;
  padding-right: 90px;
  max-width: 510px;
  width: 90%;
  margin-left: auto;
  display: flex;
  overflow: hidden;
} */

h1{
  position: relative;
  overflow: hidden;
  display: flex;
  font-weight: 400;
  font-size: 2.5em;
  font-family: "Merriweather sans" , sans-serif;
  color: #fff;
}

.leftbgimg{
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 7em);
  width: 50vw;
}

.imagebgleft{
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
}

.bgimagecity{
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.bgimagecity1{
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.transparent{
  position: absolute;
  height: calc(100% - 7em);
  width: 100%;
  top: 0;
  left: 0;
  border-left: 50vw solid rgba(8,24,34,.5);
  border-bottom: 20vw solid transparent;
}

.transparent2{
  position: absolute;
  height: calc(100% - 4em);
  width: 100%;
  top: 0;
  left: 0;
  border-left: 50vw solid #00CB75;
  border-bottom: 20vw solid transparent;
  z-index: -1;
}

.transparent1{
  position: absolute;
  height: calc(100% - 7em);
  width: 100%;
  top: 0;
  left: 0;
  border-right: 50vw solid rgba(8,24,34,.5);
  border-bottom: 20vw solid transparent;
}

.transparent3{
  position: absolute;
  height: calc(100% - 4em);
  width: 100%;
  top: 0;
  left: 0;
  border-right: 50vw solid #00CB75;;
  border-bottom: 20vw solid transparent;
  z-index: -1;
}

.cornerleft1{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 20vw solid #ffffff;
  border-right: 50vw solid transparent;
}

.cornerleft12{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 20vw solid #00CB75;
  border-right: 50vw solid transparent;
}

.cornerleft12_n{
  position: absolute;
  width: 100%;
  bottom: -42px;
  left: 0;
  border-bottom: 20vw solid #ffffff;
  border-right: 50vw solid transparent;
}

@media screen and (max-width: 940px) {
  .cornerleft12_n{
    bottom: -34px;
  }
}

.rightcontent1{
  position: absolute;
  top: 40%;
  left: 0;
  transform: translateY(-50%);
  padding-left: 73px;
  width: 90%;
  max-width: 580px;
}

@media screen and (max-width: 940px) {
  .rightcontent1{
    padding-left: 6px;
    padding-right: 2em;
  }
}

.spancontent1{
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}

.righttri{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 20vw solid #00CB75;
  border-left: 50vw solid transparent;
}

@media screen and (max-width: 940px) {
  .righttri{
    border-left: 48vw solid transparent;
  }
}

.righttri2{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 20vw solid #ffffff;
  border-left: 50vw solid transparent;
}

.righttri1{
  position: absolute;
  width: 100%;
  bottom: -48px;
  left: 0;
  border-bottom: 20vw solid #ffffff;
  border-left: 50vw solid transparent;
}
@media screen and (max-width: 940px) {
  .righttri1{
    bottom: -34px;
  }
}

.line7{
  position: absolute;
  top: 5em;
  left: 50%;
  transform: translate(-50%);
  width: 1px;
  height: 70%;
  background-color: #34a853;
  z-index: 3;
}

.gridcontent{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 14em;
  /* -moz-column-gap: 14em; */
  column-gap: 14em;
  grid-row-gap: 6em;
  row-gap: 6em;
  padding-top: 1em;
  padding-bottom: 0;
  margin-bottom: -2em;
  z-index: 1;
}

@media screen and (max-width: 940px) {
  .gridcontent{
    font-size: 0.9em;
    column-gap: 6em;
    grid-template-columns: none;
  }
  .line7{
    height: 20%;
    top: 13em;
  }
}

.heading1{
  padding-top: 0.6em;
  font-size: 1.6em;
  /* font-family: Made Mirage Medium; */
  font-family: "Merriweather sans" , sans-serif;
  display: flex;
  overflow: hidden;
}

.p{
  letter-spacing: .45px;
  color: #01232c;
  line-height: 1.6em;
  font-size: 1.2em;
}

.cutimage{
  position: relative;
  height: 20vw;
  z-index: 0;
  display: flex;
  overflow: hidden;
}

.cutimage1{
  position: relative;
  height: 100%;
  width: 50vw;
  min-width: 146px;
  overflow: hidden;
  margin-right: auto;
  left: 50%;
}

.cutimage_au{
  position: relative;
  height: 100%;
  width: 50vw;
  min-width: 200px;
  overflow: hidden;
  margin-right: auto;
  /* left: 50%; */
}

.emptybg{
  position: absolute;
  top: 0;
  z-index: 1;
  right: 0;
  border-top: 20vw solid #ffffff;
  border-right: 50vw solid transparent;
}

.emptybg1{
  position: absolute;
  top: 0;
  z-index: 1;
  right: 0;
  border-top: 20vw solid #ffffff;
  border-left: 50vw solid transparent;
}

.imagestyle1{
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  max-width: 100%;
}

.insidespan{
  box-sizing: border-box;
  display: block;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  max-width: 100%;
}

.imagejpg{
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.a{
  position: relative;
  text-decoration: none;
  color:#cecece;
  
}

.a::after{
  content: " ";
  position: absolute;
  color: #fff !important;
  background-color: #34a853;
  height: 3px;
  width: 0;
  left: 0;
  bottom: -10px;
  transition: 0.3s;
}

.a:hover::after{
  width: 100%;
}

.colo{
  color: white !important;
}

.a:hover{
  color: #fff;
}

.navi .active{
  content: " ";
  position: absolute;
  color: #fff !important;
  background-color: #34a853;
  height: 3px;
  width: 100%;
  left: 0;
  bottom: -10px;
}
.active{
  color: #34a853;
}

/* .active{
  content: " ";
  position: absolute;
  color: #fff !important;
  background-color: rgb(214, 214, 214);
  height: 3px;
  width: 100%;
  left: 0;
  /* bottom: -10px;
  color: #ff0000;
} */

/* hero page */



/* @media screen and (max-width: 940px) {
  .wrapperimg1{
    left: -1040px;
    min-width: 60%;
    max-width: 60%;
    min-height: 60%;
    max-height: 60%;
  }
} */



.bganima{
  position: relative;
  height: 100vh;
  /* width: 100px; */
  max-height: fit-content;
  /* background-color: red; */
  position: relative;
  animation-name: ani;
  animation-duration: 1s;
  animation-delay: 0.2s;
}

@keyframes ani {
  from{
    transform: translateX(0%) translateY(-40%) translateZ(0px);
  }
  to{
    transform: translateX(0%) translateY(0%) translateZ(0px);
  }
  }

.anima2{
  position: relative;
  height: 100vh;
  /* width: 100px; */
  max-height: fit-content;
  /* background-color: red; */
  position: relative;
  animation-name: zum;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-fill-mode: forwards;

}

@keyframes zum {
  from{
    transform: translateX(0%) translateY(0px) translateZ(0px) scale(1);
  }
  to{
    transform: translateX(0%) translateY(0px) translateZ(0px) scale(1.2);
  }
}

.footerimage{
  width: 20px;
}

.hamburger1{
  display: none;
}

@media screen and (max-width: 940px) {
  .hamburger1{
    display: flex;
    /* color:aliceblue; */
    position: relative;
  }
  .headerstyle{
    display: none;
  }
  .hamburger{
    display: none;
  }
}

@media screen and (max-width: 940px) {
  .itemboxnew{
    font-size: 0.7em;
    display: inline-block;
  }
}

.greentxt{
  left: 748px;
  position: absolute;
  font-weight: 200;
  font-size: 1.7em;
}

@media screen and (max-width: 940px){
  .greentxt{
    left: 0px;
    font-size: 1.3em;
  }
}

/* @media screen and (max-width: 1087px){
  .greentxt{
    font-size: 1.6em;
    padding-right: 2em;
    left: 511px;

  }
} */

.moreser{
  position: relative;
  /* left: 155px; */
  /* left: 300px; */
  left: 500%;
  top: -37px;
  letter-spacing: .1em;
  color: #ed462f;
}

@media screen and (max-width: 940px){
  .moreser{
    position: relative;
    left: 165px;
    top: -132px;
    letter-spacing: .1em;
  }

}

@media screen and (max-width: 1030px){
  .moreser{
    position: relative;
    left: 166px;
    top: 0px;
    letter-spacing: .1em;
  }

}

@media screen and (max-width: 770px){
  .moreser{
    position: relative;
    left: 166px;
    top: 0px;
    letter-spacing: .1em;
  }

}

.pe{
  position: realtive;
  overflow: hidden;
  /* left: 221px; */
  position: absolute;
  left: 255px;
}

.pe1{
  position: realtive;
  overflow: hidden;
  /* left: 221px; */
  /* position: absolute; */
  /* left: 283px; */
  padding-bottom: 1.5em;
  text-align: right;
}

@media screen and (max-width: 940px){
  .pe{
    position: absolute;
    overflow: hidden;
    left: 99px;
    top: 322px;
  }

  .pe1{
    /* position: absolute; */
    overflow: hidden;
    /* left: 112px; */
    top: 233px;
    text-align: center;
  }
}

/* .card{
  background: rgb(174, 174, 174);
  border-radius: 30px;
  width: 150px;
  height: 150px;
} */

.fakecard{
  background-color: #f5f6f7;
  border-radius: 2rem;
  box-sizing: border-box;
  margin-bottom: 2.4rem;
  margin-right: 0;
  width: 100%;
  /* background-image: linear-gradient(#6c6c6c, #969696); */
}

.fakecard:hover{
  background-color: #edf1f4;
}

.fakecard1{
  background-color: #fafafa;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 2.4rem;
  margin-right: 0;
  width: 100%;
  box-shadow: 0px 0px 1px 1px rgb(241 241 241);
  border-left: 5px solid #fafafa;
  /* background-image: linear-gradient(#6c6c6c, #969696); */
}

.fakecard1:hover{
  background-color: #f7f7f7;
  border-left: 5px solid #34a853;
}

.semic{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
  width: 200px;
  border-radius: 150px 150px 0 0;
  background-color: green;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0
}

/* body {
  background: #eee;
  font-family: 'Ubuntu', sans-serif
} */

.box {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px
  
}

.box_n {
  position: relative;
  width: 115%;
  padding-right: 15px;
  padding-left: 15px
  
}

.position_box{
  left: -82px;
}

@media screen and (max-width: 940px){
  .box_n {
    /* position: relative; */
    width: 100%;
    padding-right: 15px;
    padding-left: 15px 
  }
  
  .position_box{
    left: 0px;
  }
}

.our-services {
  margin-top: 75px;
  padding-bottom: 30px;
  padding: 0 47px;
  min-height: 198px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  transition: all .4s ease-in-out;
  box-shadow: 0 0 25px 0 rgba(20, 27, 202, .05)
}

.our-services_n {
  margin-top: 75px;
  padding-bottom: 30px;
  /* padding: 0 47px; */
  min-height: 198px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  transition: all .4s ease-in-out;
  box-shadow: 0 0 25px 0 rgba(20, 27, 202, .05)
}

.our-services .icon {
  margin-bottom: -21px;
  transform: translateY(-50%);
  text-align: center
}

.our-services:hover h4,
.our-services:hover p {
  /* color: #fff */
  color: #000000;
}

.speedup:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, .05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, rgb(0, 224, 6,0.5) 0%, rgb(0, 203, 117,0.5) 100%, rgb(0, 176, 101,0.5) 50%)
}

.settings:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, .05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, rgb(0, 224, 6,0.5) 0%, rgb(0, 203, 117,0.5) 100%, rgb(0, 176, 101,0.5) 50%)
}

.privacy:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, .05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, rgb(0, 224, 6,0.5) 0%, rgb(0, 203, 117,0.5) 100%, rgb(0, 176, 101,0.5) 50%)
}

.backups:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, .05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, rgb(0, 224, 6,0.5) 0%, rgb(0, 203, 117,0.5) 100%, rgb(0, 176, 101,0.5) 50%)
  /* background-image: linear-gradient(-45deg, #00E006 0%, #00CB75 100%, #00B065 50%) */
  }

.database:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, .05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, rgb(0, 224, 6,0.5) 0%, rgb(0, 203, 117,0.5) 100%, rgb(0, 176, 101,0.5) 50%)
}

.newhead{
  /* padding-top: 0.6em; */
  margin-bottom: 1em;
  font-size: 1.6em;
  /* font-family: Made Mirage Medium; */
  font-family: "Merriweather sans" , sans-serif;
  line-height: 1.6em;
  /* color: rgb(0, 0, 0); */
  color: #34a853;
  letter-spacing: .45px;
}

.infobullets{
  font-size: 1.2em;
  line-height: 1.6em;
  letter-spacing: 0.45px;
}

.grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-gap: 12em;
  gap: 12em; */
}

.row_n{
  /* --bs-gutter-x: 1.5rem; */
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  display: flex;
  justify-content: space-around;
}

.cookie-content{
  color: #000000;
  padding-top: 5em;
  margin-bottom: 3em;
}

.blackbox{
  width: 214px;
  height: 162px;
  background-color: #000000;
  border-radius: 22px;
}

.herotxt{
  text-align: center;
  color: white;
  font-family: "Merriweather sans" , sans-serif;
  font-size: 28px;
  margin-top: 26px;
}

@media screen and (max-width: 940px) {
  .herotxt{
    font-size: 18px;
    padding-left: 1em;
    padding-right: 1em;
  }
}

.more_btn{
  left: 75%;
  top: -12px;
}

@media screen and (max-width: 940px) {
  .more_btn{
    left: 45%;
    top: -12px;
  }
}

/* @media screen and (max-width: 1020px){
  .greentxt{
    left: 511px;
    padding-right: 2em;
  }
} */

@media screen and (max-width: 1030px){
  .rightcontent1 {
    position: absolute;
    top: 40%;
    left: 0;
    padding-left: -24px;
    width: 80%;
    max-width: 581px;
  }
}
