.navbar {
    width: 100%;
    height: 67px;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: black;
    z-index: 100;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
    font-family: "Merriweather sans" , sans-serif;
}

.underline {
    width: 3px;
    height: 8px;
    background: rgb(249, 249, 249);
    transition: width 2s;
}

.underline:hover {
    width: 50px;
}

.navbar img {
    width: 166px;
    top: 8.7px;
    position: absolute;
    left: 9px;
    box-shadow: 0px 2px 17px rgb(0 0 0 / 10%);
}

.navm {
    display: flex;
    position: absolute;
    right: 0;
    font-size: 1.2em;
    margin-bottom: 0;
    padding-right: 4em;
}

.navi {
    padding: 1rem;
}

/* .active::after {
    font-weight: bold;
    content: " ";
    position: absolute;
    color: #fff !important;
    background-color: #34a853;
    height: 3px;
    width: 100%;
    left: 0;
    bottom: -10px;
    transition: 0.3s;
} */


.active .a {
    color: #fff;
}

.hamburger {
    display: none;
}

.ul:hover {
    color: black !important;
}

@media screen and (max-width: 940px) {
    .upper-nav-bar {
        display: none;}
    
    /* .navm {
        position: fixed;
        left: -100%;
        top: 100px;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: 0.4s;
    } */
    
    /* .navm.active {
        left: 0;
    } */

    /* .navi {
        margin: 1.5rem;
        font-size: 1.8rem;
        justify-content: center;
    } */

    .hamburger {
        display: flex;
        color: aliceblue;
        position: relative;
    }
}

.sticky {
    position: fixed;
    top: 0;
}

.navst {
    --bs-offcanvas-width: 200px !important;
}

.under {
    height: 3px;
    width: 100%;
    top: 4px;
    position: inherit;
    color: #fff !important;
    background-color: #34a853;
}

/* Arrow styling (Upward-facing) */
.arrow-up {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #636363;
}

/* Dropdown styling */
/* Base Style for Service Dropdown */
.service-dropdown {
    position: fixed;
    width: 100%;
    left: 0;
    background-color: #222222;
    padding: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: flex;
    justify-content: center;
    font-size: 15px;
    flex-direction: row;
    text-transform: uppercase;
    gap: 20px;
    box-sizing: border-box;
    overflow: visible;
    margin-bottom:2%;
}

/* Adjust position and layout for medium screens */
/* @media screen and (max-width: 1200px) {
    .service-dropdown {
        top: 6vh; 
        font-size: 14px;
    }
} */

/* Adjust position, layout, and font for smaller screens */
@media screen and (max-width: 1400px) {
    .service-dropdown {
        top: 4em;
        gap: 30px; 
      
    }
}


/* Style adjustments for larger screens */
@media screen and (min-width: 1400px) {
    .service-dropdown {
        top: 4em; 
        gap:40px;
        padding-left: 15%;
        
        
    }
   
}


.service-dropdown a {
    color: white;
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    white-space: nowrap;
}

.service-dropdown a:hover {
    border-bottom: 3px solid #34a853;
    transition: width 2s;
}
.service-link.active{
    color: #34a853;
    font-weight: 600;
}

.country-dropdown-content {
    position: absolute;
    align-items: center;
    min-width: 120px; 
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 100%;
    
}

.dropbtn {
    color: #34a853;
    background-color: black;
    border: 1px solid black;
}

.country-dropdown-content button {
    background: none;
    border: none;
    background-color: #222222;
    color: white;
    padding: 10px 15px;
    
    width: 100%;
    cursor: pointer;
    font-size: 15px;
    text-align:center;
    text-transform: uppercase;
    
                    
                    
}

  
/* .active-link {
    font-weight: bold;
    color: #34a853;
} */


   
/* Mobile View Styles */
@media (max-width: 768px) { /* Adjust the max-width as needed for your breakpoint */
    .offcanvas-body {
      padding: 1rem; /* Add padding for offcanvas */
    }
    .desktop-view{
      display:none
    }
    .navbar{
        background-color: #fff;
        box-shadow: none;
    }
    .drpdown-list {
      list-style: none;
      padding: 0;
      margin: 0;
      position: absolute;
      background: #343a40; /* Background color to match your navbar */
      z-index: 1000;
      width: 100%; /* Ensures it takes full width */
    }
  
    .dropdown-list li {
      padding: 10px; /* Spacing for dropdown items */
    }
  
    .dropdown-list li a,
    .dropdown-list li button {
      color: white; /* Text color for dropdown items */
      text-align: left; /* Align text to the left */
      display: block; /* Makes the whole area clickable */
      width: 100%; /* Ensure full width for buttons */
    }
  
    .dropdown-list li a:hover,
    .dropdown-list li button:hover {
      background-color: rgba(255, 255, 255, 0.1); /* Hover effect */
    }
  
   }
  
  