.clients-we-serve{
  margin:0 15%;
}




.clients-row {
  display: flex;
  gap: 30px; /* Space between clients cards */
  justify-content: center;
  margin-bottom: 3%; /* Space between rows */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.clients-card {
  font-size: 16px; /* Default font size for cards */
  text-align: center;
  max-width: 200px; /* Limit width of cards */
  flex: 1 1 200px; /* Make cards responsive */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for cards */
  border-radius: 8px; /* Optional border radius */
  padding: 15px; /* Padding inside cards */
  background-color: #f9f9f9; /* Optional background color */
  transition: transform 0.2s; /* Add a hover effect */
}

.clients-card:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.clients-card-list {
 /* Left align list */
  margin: 5% 0; /* Space around list */
  font-size: small; /* Font size for list items */
}

.divider {
  width: 2px; /* Width of the divider */
  background-color: #34a853; /* Color of the divider */
  height: auto; /* Height to adjust automatically */
  margin: 0 10px; /* Space around divider */
}

/* Increased width for specific clients cards */
.clients-card-list-container {
  max-width: 400px; /* Increase max-width for cards with lists */
  width: 100%; /* Allow the card to take full width of its parent */
  align-items: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .clients-title {
    font-size: 1.8em; /* Adjust font size for smaller screens */
  }

  .clients-card {
    font-size: 1.2em; /* Smaller font size for cards */
    max-width: 150px; /* Limit width on smaller screens */
  }

  .clients-card-list {
    font-size: x-small; /* Smaller font size for list items */
  }

  .clients-row {
    gap: 20px; /* Smaller gap between items */
  }
}

@media (max-width: 480px) {
  .clients-title {
    font-size: 1.5em; /* Further reduce font size */
  }

  .clients-card {
    font-size: 1em; /* Further reduce font size */
    max-width: 100%; /* Full width on very small screens */
  }

  .clients-card-list {
    margin: 3% 0; /* Reduce margin around list */
  }
}
