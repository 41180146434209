/* Container for the entire section */
.teamImage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
/* Larger gap between sections for a clean look */
   padding:0 15%; /* Padding around the container */
  background-color: #f9f9f9; /* Light background color for contrast */
}

/* Section title styles */
.teamImage-section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #34a853;
  text-transform: uppercase;
  
}

/* Management card container */
.teamImage-management-card,
.teamImage-advisor-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem; /* Spacing between cards */
}

/* Card container for individual team members/advisors */
.teamImage-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff; /* White background for cards */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  border-radius: 12px; /* Rounded corners */
  padding: 2rem;
  width: 100%; /* Full-width for responsiveness */
  max-width: 900px; /* Maximum width for larger screens */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

/* Hover effect for cards */
.teamImage-card-container:hover {
  transform: translateY(-10px); /* Lift the card slightly on hover */
}

/* Image and LinkedIn logo container */
.teamImage-image-card {
  margin-bottom: 1.5rem; /* Spacing between image and details */
}

.teamImage-profile-img {
  width: 150px;
  height: 150px;
  border-radius: 50%; 
  object-fit: cover;
  border:1px solid white;
  padding: 1px;
  border: 4px solid #34a853;
}

/* Profile details */
.teamImage-profile-details {
  text-align: center; /* Center-align text */
}

.teamImage-profile-name {
  color: #34a853; /* Green color for the name */
  font-weight: bold;
  font-size: 1.2rem;
}

.teamImage-profile-title {
  color: #000; /* Black color for the designation */
  font-size: 1rem;
}

.teamImage-profile-description {
  
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1.5rem;
  text-align: left; /* Left-align the description */
}

.teamImage-name-designation-container {
  display: flex;
  justify-content: center;
  align-items:last baseline;
  /* Adjust the spacing between name and designation */
}

/* LinkedIn logo */
.teamImage-linkedin-container {
  margin-top: 1rem;
}

.teamImage-linkedin-logo {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease;
}

.teamImage-linkedin-logo:hover {
  transform: scale(1.2); /* Enlarge the logo slightly on hover */
}
.teamImage-more-details{
  color:#34a853;
}
.teamImage-more-details:hover{
  color:#34a853;
  font-weight: 700;
}
/* Responsive styles */
@media (min-width: 768px) {
  /* Larger screens: align items in a row */
  .teamImage-card-container {
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
    text-align: left; /* Align text to the left on larger screens */
  }

  .teamImage-profile-details {
    flex: 1;
    text-align: left;
  }
}

@media (max-width: 767px) {
  /* Smaller screens: stack items vertically */
  .teamImage-card-container {
    width: 100%; /* Full width for mobile */
    text-align: center; /* Center-align text for smaller screens */
  }

  .teamImage-profile-img {
    margin-bottom: 1rem;
  }
}
