.country-service-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 15%;
  width: 100%;
  box-sizing: border-box;
  font-family: "Merriweather Sans";
  text-align: left;
}

.country-service-list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
  box-sizing: border-box;
}

.service-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.country-service-list-item {
  margin-bottom: 10px;
  font-size: 1rem;
  position: relative;
  text-align: left;
  font-weight: normal;
  cursor: pointer;
}

.country-service-list-item.active {
  font-weight: bold;
  color: black;
}

.country-service-arrow-link {
  margin-left: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.country-service-arrow-icon {
  width: 10px;
  vertical-align: middle;
}

.country-service-nested-list {
  align-items: start;
  list-style-type: none;
  padding-left: 20px;
  margin-top: 5px;
  text-align: left;
}

.country-service-divider {
  width: 2px;
  background-color: #34a853;
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
}

.country-service-details-container {
  display: flex;
  padding-left: 10px;
  width: 45%;
  box-sizing: border-box;
  text-align: left;
}

/* Consistent line height for paragraphs and list items */
.country-service-details-container p,
.additional-info-list li {
  line-height: 1.5; /* Adjust as needed */
}

.additional-info-list {
  list-style: disc;
  gap: 10px;
  padding-left: 20px;
  margin-top: 10px;
  text-align: left;
}

.additional-info-list li {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .country-service-wrapper {
    flex-direction: column;
    align-items: center;
    padding: 0 5%;
  }

  .country-service-list,
  .country-service-details-container {
    width: 100%;
  }

  .country-service-list-item {
    font-size: 0.9rem;
  }

  .country-service-arrow-icon {
    width: 16px;
  }

  .country-service-divider {
    height: 2px;
    width: 80%;
    margin: 20px 0;
  }
  .country-service-details-container{
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .country-service-list-item {
    font-size: 0.85rem;
    padding: 0 5%;
  }

  .country-service-arrow-icon {
    width: 14px;
  }

  .country-service-nested-list {
    padding-left: 15px;
  }
}
